import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AceYourVirtualInterview from "../BlogPages/AceYourVirtualInterview";
import SoftSkillsInInterviews from "../BlogPages/SoftSkillsInInterviews";
import QuickInterviewPrep from "../BlogPages/QuickInterviewPrep";
import HateJobInterviews from "../BlogPages/HateJobInterviews";
import ScoopOnHiringDecisions from "../BlogPages/ScoopOnHiringDecisions";
import GooglesInterviewStyle from "../BlogPages/UnderstandingGoogleInterview";

const RenderBlogPage = () => {
  const { blogId } = useParams();
  const [loading, setLoading] = useState(true);

  // Simulating a loading phase
//   useEffect(() => {
//     const timer = setTimeout(() => setLoading(false), 500); // Example delay of 500ms
//     return () => clearTimeout(timer);
//   }, []);

//   if (loading) return <p>Loading...</p>;

  // Render the component based on blogId
  if (blogId === "ace-your-virtual-interview") {
    return <AceYourVirtualInterview />;
  }
  if (blogId === "why-you-shouldnt-undersell-your-soft-skills") {
    return <SoftSkillsInInterviews />;
  }
  if (blogId === "how-to-prepare-for-interviews-when-you-dont-have-much-time") {
    return <QuickInterviewPrep />;
  }
  if (blogId === "why-do-most-candidates-hate-job-interviews") {
    return <HateJobInterviews />;
  }
  if (blogId === "how-candidates-are-evaluated-and-chosen") {
    return <ScoopOnHiringDecisions/>;
  }
  if (blogId === "how-candidates-are-evaluated-and-chosen") {
    return <ScoopOnHiringDecisions/>;
  }
  if (blogId ==="understanding-googles-interview-style"){
    return <GooglesInterviewStyle/>
  }



  // Default case for unknown blogId
  return <p>Blog ID not recognized.</p>;
};

export default RenderBlogPage;
