// src/components/Tab.jsx

import React from "react";
import { motion } from "framer-motion";
import { Lock } from "lucide-react";

const TemplateTab = ({ tab, isActive, onClick }) => {
  const tabVariants = {
    hover: {
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
  };

  const activeStyle = {
    background: "linear-gradient(21deg, rgb(84 77 183) 33.59%, rgb(63, 59, 133) 59.88%, rgb(49, 45, 100) 87.87%)",
    backdropFilter: "blur(10px)",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.06) 0px 1px 3px",
    border: "2px solid #4f46e5",
  };

  const inactiveStyle = {
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(10px)",
    boxShadow:
      "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
  };

  return (
    <motion.div
      className={`flex flex-col items-center p-4 w-32 rounded-lg cursor-pointer transition-transform transform ${
        tab.locked
          ? "blur-sm cursor-not-allowed"
          : "text-gray-400 hover:text-white hover:bg-[#ffffff12]"
      }`}
      onClick={onClick}
      whileHover={!tab.locked ? "hover" : ""}
      variants={tabVariants}
    >
      <div
        className="p-2 rounded-full flex items-center justify-center shadow-lg"
        style={isActive ? activeStyle : inactiveStyle}
      >
        {tab.locked ? (
          <Lock className="text-gray-500" />
        ) : tab.image ? (
          <img
            src={tab.image}
            alt={tab.label}
            className="h-8 w-8 rounded-full object-cover"
          />
        ) : (
          <div className="p-1 text-2xl">{tab.icon}</div>
        )}
      </div>
      <span className="text-sm font-medium mt-2">{tab.label}</span>
    </motion.div>
  );
};

export default TemplateTab;
