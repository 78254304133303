import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Templates from "./Pages/Templates/Templates.jsx";
import bgShadows from "../src/assets/bgShadows.png";
import EachTemplate from "./Pages/EachTemplate/EachTemplate.js";
import TemplatesProcessing from "./Pages/EachTemplate/TemplateProcessing/TemplatesProcessing.jsx";
import AboutUs from "./Pages/Aboutus/Aboutus.jsx";
import ContactUs from "./Pages/Contactus/Contactus.jsx";
import BlogPage from "./Pages/BlogPage/Blogpage.jsx";
import FAANGTeam from "./Pages/FaangTeam/FaangTeam.jsx";
import ErrorPage from "./Pages/ErrorPage/ErrorPage.jsx";
import RenderBlogPage from "./Pages/BlogPage/RenderBlogPage/RenderBlogPage.js";
import TermsAndPrivacy from "./Pages/TermsAndPrivacy/TermsOfUse.jsx";
import PrivacyPolicy from "./Pages/TermsAndPrivacy/PrivacyPolicy.jsx";
import JobCareers from "./Pages/JobCareers/JobCareers.jsx";
import ApplyForJob from "./Pages/JobCareers/ApplyForJob/ApplyForJob.jsx";
import JobSuccess from "./Pages/JobCareers/JobSuccess/JobSuccess.jsx";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import GooglinessLandingPage from "./Pages/TemplatePages/GooglinessLandingPage.js";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDZVl_pBQ02vRhlBd87V0gbWihj9z4_5s4",
  authDomain: "faangb2c-landingpage.firebaseapp.com",
  projectId: "faangb2c-landingpage",
  storageBucket: "faangb2c-landingpage.firebasestorage.app",
  messagingSenderId: "607482203571",
  appId: "1:607482203571:web:58510f3cf9abc3b732cfc6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Authenticated Route Wrapper
function ProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
}

// Login Component
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log("handleLogin")
      navigate('/')
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/templates')
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen">
      <form onSubmit={handleLogin} className="bg-white p-6 rounded shadow-md w-80">
        <h1 className="text-lg font-bold mb-4">Login</h1>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded">
          Login
        </button>
      </form>
    </div>
  );
}

export default function  App() {
  const location = useLocation();
  const containerRefscroll = useRef(null);

  useEffect(() => {
    if (containerRefscroll.current) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        backgroundImage: `url(${bgShadows})`,
        backgroundRepeat: "no-repeat",
        background:
          "radial-gradient(100% 149% at 50% 18%, rgb(35 33 74) 0%, rgba(30, 28, 74, 0.96) 0%, rgb(15, 11, 28) 50%, rgb(17 17 17) 100%)",
      }}
      className="min-h-screen flex flex-col"
      ref={containerRefscroll}
    >
      <header className="sticky top-0 z-50">
        <Navbar />
      </header>
      <main className="flex-grow">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/templates" element={<Templates />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/contactus" element={<ContactUs />} />
                  <Route path="/blogs" element={<BlogPage />} />
                  <Route path="/career-page" element={<JobCareers />} />
                  <Route path="/career-page/:jobID" element={<ApplyForJob />} />
                  <Route path="/career-page/:jobID/success" element={<JobSuccess />} />
                  <Route path="/blogs/:blogId" element={<RenderBlogPage />} />
                  <Route path="/faang-team" element={<FAANGTeam />} />
                  <Route path="/googlinessinterview" element={<GooglinessLandingPage />} />
                  <Route path="/terms-of-use" element={<TermsAndPrivacy />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/templates/:templateId" element={<EachTemplate />} />
                  <Route path="/templates/:templateId/:requestID" element={<TemplatesProcessing />} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}


