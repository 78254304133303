// src/data/tabs.js

import faang from "../../../assets/lighticon.png";
import { Rocket, Code, Database, Cog, BarChart2, Brain, Cloud } from "lucide-react";

const tabsData = [
  { id: 1, icon: null, image: faang, label: "FAANG Interviews", locked: false },
  { id: 2, icon: <Rocket />, label: "Funny Templates", locked: false },
  { id: 3, icon: <Code />, label: "Software", locked: true },
  { id: 4, icon: <Database />, label: "Data Science", locked: true },
  { id: 5, icon: <Cog />, label: "AI & ML", locked: true },
  { id: 6, icon: <Rocket />, label: "Finance", locked: true },
  { id: 7, icon: <BarChart2 />, label: "Marketing", locked: true },
  { id: 8, icon: <Brain />, label: "Consulting", locked: true },
  { id: 9, icon: <BarChart2 />, label: "Business", locked: true },
  { id: 10, icon: <Cloud />, label: "Cloud Computing", locked: true },
];

export default tabsData;
