// src/components/TemplatesPage.jsx

import React, { useState } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { slugify } from "./utils/slugify";
import cardData from "./data/cardData";
import tabsData from "./data/tabsData";
import TemplateCard from "./components/TemplateCard";
import TemplateTab from "./components/TemplateTab";

const TemplatesPage = () => {
  const [activeTab, setActiveTab] = useState(1); // Default to FAANG Interviews
  const navigate = useNavigate();

  const handleRunTemplate = (template) => {
    console.log("template", template);

    if (template.templateType === "Mock Interview Template") {
      // Serialize the template object into query parameters
      const queryParams = new URLSearchParams({ 
        id: template.id, 
        title: template.title, 
        from: "landingPage"
      }).toString();

      console.log("queryParams", queryParams);

      // Redirect with query parameters
      // window.open(`http://dashboard.faangai.com/?${queryParams}`, '_blank');
      // window.open(`http://localhost:3000/?${queryParams}`, '_blank');
      window.open(`http://dev.dashboard.faangai.com/?${queryParams}`, '_blank');
      return;
    }

    if (!template.locked) {
      const slug = slugify(template.title);
      navigate(`/templates/${slug}`);
    } else {
      console.log("Template is locked.");
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Mock Interviews | FaangAI</title>
        <meta name="description" content="Choose from 100+ expert-vetted interviews, get feedback, and land your dream job." />
      </Helmet>

      <motion.div
        className="min-h-screen text-gray-200 font-sans mb-[300px]"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <header className="text-center py-8">
          <h1 className="text-4xl font-extrabold">Mock Interviews</h1>
          <p className="text-gray-400 mt-2 text-lg">
            Choose from 100+ expert-vetted interviews, get feedback, and land your dream job.
          </p>
        </header>
        <hr className="border-b border-gray-700" />

        <nav className="flex overflow-x-auto whitespace-nowrap px-6 py-4 space-x-6 scrollbar-hide">
          {tabsData.map((tab) => (
            <TemplateTab
              key={tab.id}
              tab={tab}
              isActive={activeTab === tab.id}
              onClick={() => !tab.locked && setActiveTab(tab.id)}
            />
          ))}
        </nav>

        <motion.div
          className="mt-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-6 py-8"
          variants={containerVariants}
        >
          {cardData
            .filter((card) => card.category === activeTab)
            .map((card) => (
              <TemplateCard
                key={card.id}
                card={card}
                handleRunTemplate={handleRunTemplate}
              />
            ))}
        </motion.div>
      </motion.div>
    </>
  );
};

export default TemplatesPage;
