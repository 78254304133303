// src/components/Card.jsx

import React from "react";
import { motion } from "framer-motion";
import { MdOutlineTimer } from "react-icons/md";

const TemplateCard = ({ card, handleRunTemplate }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const iconSectionStyle = (card) => {
    if (card.category === 1) {
      return {
        background: "#ffffff61",
        backdropFilter: "blur(10px)",
        boxShadow:
          "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
        border: "0.5px solid rgba(255, 255, 255, 0.2)",
      };
    } else if (card.category === 2) {
      return {
        background: "#525286a1",
      };
    }
    return {};
  };

  return (
    <motion.div
      className="relative bg-[#2c2949] backdrop-blur-md border border-gray-700 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300 p-4 flex flex-col text-white"
      style={{
        boxShadow:
          "0 8px 20px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(255, 255, 255, 0.1)",
      }}
      variants={cardVariants}
      whileHover={{ scale: 1.02 }}
    >
      {/* Icon Section */}
      <div
        className={`h-[170px] rounded-xl bg-gradient-to-br ${card.gradient} flex items-center justify-center mb-4 text-5xl p-20`}
      >
        {card.category === 1 && (
          <div
            className="p-2 rounded-full flex items-center justify-center shadow-lg"
            style={iconSectionStyle(card)}
          >
            <img
              src={card.imageIcon}
              alt={card.title}
              style={{
                height: `${card.size.height}px`,
                width: `${card.size.width}px`,
              }}
              className="rounded-full object-cover"
            />
          </div>
        )}
        {card.category === 2 && (
          <div className="w-26 h-26 bg-[#525286a1] rounded-full flex items-center justify-center shadow-inner shadow-lg">
            <img
              src={card.imageIcon}
              alt={card.title}
              className={`object-contain ${
                card.title === "Super Buddy"
                  ? "w-[85px] h-[91px] mr-2"
                  : "w-[95px] h-[95px] p-3"
              }`}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col p-3 h-full">
        {/* Title */}
        <h2 className="text-lg font-bold text-gray-200 mb-3">
          {card.title}
        </h2>

        {/* Description */}
        <p className="text-gray-300 text-sm leading-relaxed mb-4 flex-grow">
          {card.description}
        </p>

        {/* Difficulty and Time */}
        {card.difficulty && card.time && (
          <div className="flex justify-between items-center text-sm text-gray-400 mb-4">
            <span className="flex items-center gap-1 text-[13px]">
              <MdOutlineTimer className="text-indigo-300" size={20} />
              {card.time}
            </span>
            <span
              className={`text-[12px] px-3 py-1 rounded-full font-medium ${
                card.difficulty === "Easy"
                  ? "bg-green-600 text-green-200"
                  : card.difficulty === "Medium"
                  ? "bg-yellow-600 text-yellow-200"
                  : "bg-red-600 text-red-200"
              }`}
            >
              {card.difficulty}
            </span>
          </div>
        )}

        {/* Button */}
        <div className="mt-auto">
          {!card.locked ? (
            <button
              className="w-full bg-gradient-to-r from-indigo-500 to-blue-600 text-white font-semibold py-3 rounded-lg hover:from-blue-600 hover:to-teal-500 transition-all duration-300 ease-in-out shadow-lg hover:shadow-2xl transform hover:-translate-y-1 focus:outline-none"
              onClick={() => handleRunTemplate(card)}
            >
              Run Template
            </button>
          ) : (
            <div className="flex items-center justify-center text-red-400 font-semibold py-3">
              🔒 Unlock with 1000 coins
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default TemplateCard;
