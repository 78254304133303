// src/data/templateCards.js

import googleLogo from "../../../assets/google.png";
import personalityRoasterImage from "../../../assets/personality_roaster2.png";
import salaryNinjaImage from "../../../assets/salary_ninja3.png";
import buddyRocketImage from "../../../assets/super_buddy.png";
import amazonLogo from "../../../assets/amazon.png";
import metaLogo from "../../../assets/metaLogo.png";



const cardData = [
 
  {
    id: "google-googlyness",
    category: 1, // FAANG Interviews
    imageIcon: googleLogo,
    templateType: "Mock Interview Template",
    title: "Google’s Googliness",
    description: "Showcase how you align with Google’s culture of innovation and teamwork.",
    difficulty: "Medium",
    time: "10m",
    locked: false,
    gradient: "from-[#eb4132b0] via-[#fcbe0061] to-[#3b79e6]",
    size: { height: 50, width: 50 },
  },
  {
    id: "amazon-leadership-principles",
    category: 1, // FAANG Interviews
    imageIcon: amazonLogo,
    title: "Amazon’s Leadership Principles",
    templateType: "Mock Interview Template",
    description: "Test your familiarity with Amazon's Leadership Principles.",
    difficulty: "Medium",
    time: "10m",
    locked: false,
    gradient: "from-[#fa9825] via-[#fcbe0061] to-[#92979f]",
    size: { height: 50, width: 50 },
  },
  {
    id: "meta-product-sense-interviews",
    category: 1, // FAANG Interviews
    imageIcon: metaLogo, // Meta logo
    title: "Meta’s Product Sense Interviews",
    templateType: "Mock Interview Template",
    description: "Solve problems by designing innovative product solutions.",
    difficulty: "Medium",
    time: "10m", 
    locked: false,
    gradient: "from-[#0084ff] via-[#00aaff] to-[#00c5e7]", // Gradient colors for styling
    size: { height: 50, width: 50 }
  },  
  {
    id: 'personality-roaster',
    category: 2,
    imageIcon: personalityRoasterImage,
    title: "Personality Roaster",
    description:
      "Drop your CV, and let Personality Roaster turn up the heat with a roast you won’t forget! 🔥",
    locked: false,
    gradient: "from-purple-800 to-indigo-900",
    size: { height: 70, width: 70 },
  },
  {
    id: 'super-buddy',
    category: 2,
    imageIcon: buddyRocketImage,
    title: "Super Buddy",
    description: "Discover your inner superhero and unleash your powers! 🚀",
    locked: false,
    gradient: "from-purple-800 to-indigo-900",
    size: { height: 70, width: 70 },
  },
  {
    id: 'salary-negotiation',
    category: 2,
    imageIcon: salaryNinjaImage,
    title: "Salary Negotiation",
    description:
      "Unlock expert tips to master salary negotiations and land your dream compensation. Collect 1000 coins to access this feature! 🔒",
    locked: true,
    gradient: "from-purple-800 to-indigo-900",
    size: { height: 70, width: 70 },
  },
];

export default cardData;
